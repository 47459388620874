import {createMessenger} from '@userlike/messenger';

export default class Userlike {
    constructor(elem) {
        this.el = elem;

        this.userlike = this.createApi();

        this.userlike.then(
            (messenger) => messenger.mount()
                .then(() => messenger.setVisibility({
                    main: true,
                    button: false,
                    notifications: false,
                })),
        );

        this.el.addEventListener('click', () => this.startChat());
    }

    // eslint-disable-next-line class-methods-use-this
    async createApi() {
        const result = await createMessenger({
            version: 1,
            widgetKey: '3c9196f6da394803ada831a793b9d2c7838269db507240f3b781fa5046701c5f',
        });
        const {api} = result.value;
        return api;
    }

    startChat() {
        this.userlike.then((m) => m.maximize());
    }
}
