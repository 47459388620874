import * as Sqrl from 'squirrelly';

export default class quiz {
    config = {
        quizItemTpl: '<div class="question-wrapper"> <h2 class="mb-3">{{it.headline}}</h2> '
            + '<div class="answers {{it.pos}}">{{@each(it.answers) => answer, index}}\n'
            + '<div class="answer solution-{{answer.solution}}"><input type="radio" name="answer" '
            + 'id="answer-{{index}}" data-solution="{{answer.solution}}" class="answer">'
            + '<label for="answer-{{index}}">{{answer.value}}</label></div>\n'
            + '{{/each}}</div><div class="resolution row"><div class=" col-12 col-lg-4">'
            + '<div class="img text-center"><img src="{{it.image}}" alt=""></div> </div>'
            + '<div class="col-12 col-lg-8"><div class="rte mb-3">{{it.text| safe}}</div></div> </div></div>',
        nextSelector: '#quizNext',
        fromSelector: '#quizFrom',
        toSelector: '#quizTo',
        questionWrapperSelector: '#questionWrapper',
        againSelector: '#quizAgain',
        offset: 100,
    };

    constructor(elem, config) {
        this.$el = elem;
        this.config = {...this.config, ...config};

        // eslint-disable-next-line no-undef
        this.data = quizConfig[0].rows || [];

        this.$next = this.$el.querySelector(this.config.nextSelector);
        this.$current = this.$el.querySelector(this.config.fromSelector);
        this.$max = this.$el.querySelector(this.config.toSelector);
        this.$wrapper = this.$el.querySelector(this.config.questionWrapperSelector);
        this.$again = this.$el.querySelector(this.config.againSelector);

        this.setMaxPos(this.data.length);
        this.setCurrentPos(0);

        this.init();
    }

    init() {
        this.$wrapper.innerHTML = this.prepareQuestion();

        this.registerEvent();
    }

    registerEvent() {
        const me = this;

        this.$next.addEventListener('click', () => {
            me.nextQuestion();
            me.scrollInToView();
        });

        this.$again.addEventListener('click', () => {
            me.setCurrentPos(0);
            this.$el.classList.remove('finished');
            me.$wrapper.innerHTML = me.prepareQuestion();
        });

        this.$wrapper.addEventListener('click', () => {
            if (me.isChecked()) {
                this.$el.classList.add('show-answer');
                // check if last
                if (this.currentPos + 1 === this.data.length) {
                    this.$el.classList.add('finished');
                }
            }
        });
    }

    scrollInToView() {
        window.scroll({top: (this.$el.offsetTop - this.config.offset), left: 0, behavior: 'smooth'});
    }

    isChecked() {
        let c = false;
        this.$wrapper.querySelectorAll('input').forEach((elem) => {
            if (elem.checked) c = true;
        });

        return c;
    }

    nextQuestion() {
        if (this.currentPos + 1 < this.data.length) {
            this.setCurrentPos(this.currentPos + 1);
            this.$wrapper.innerHTML = this.prepareQuestion();
        }
    }

    setCurrentPos(pos) {
        this.currentPos = pos;
        this.$current.innerHTML = pos + 1;
    }

    setMaxPos(pos) {
        this.currentPos = pos;
        this.$max.innerHTML = pos;
    }

    prepareQuestion() {
        this.$el.classList.remove('show-answer');
        const data = this.getData(this.currentPos);
        this.shuffle(data.answers);

        return Sqrl.render(this.config.quizItemTpl, data);
    }

    /**
     * Return data by index.
     * @param index
     */
    getData(index) {
        return {
            headline: this.data[index].question.value,
            image: this.data[index].image.url,
            text: this.data[index].text.value,
            pos: this.data[index].pos.value,
            answers: [
                {
                    value: this.data[index].right_answer.value,
                    solution: true,
                },
                {
                    value: this.data[index].wrong_answer_1.value,
                    solution: false,
                },
                {
                    value: this.data[index].wrong_answer_2.value,
                    solution: false,
                },
            ],
        };
    }

    /**
     * Shuffles array in place. ES6 version
     * @param {Array} a items An array containing the items.
     */
    // eslint-disable-next-line class-methods-use-this
    shuffle(a) {
        // eslint-disable-next-line no-plusplus
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            // eslint-disable-next-line no-param-reassign
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }
}
