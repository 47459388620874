/**
 * Video.class
 *
 * Handle video implementation on detail pages.
 *
 * (c) notwenidges übel UG, Dimitri Preiß (preiss@notwenidges-uebel.de)
 */

import ResizeObserver from 'resize-observer-polyfill';

export default class Video {
    options = {
        btnSelector: '.btn',
    };

    constructor(elem, config) {
        this.options = {...this.options, ...config, ...elem.dataset};
        this.$el = elem;

        this.init();
    }

    init() {
        this.$btn = this.$el.querySelector(this.options.btnSelector);
        this.$video = this.$el.querySelector('video');
        this.$source = this.$video.querySelector('source');
        this.current = null;

        this.registerEvent();
    }

    registerEvent() {
        const me = this;

        this.resizeObserver = new ResizeObserver((entries, observer) => this.resizeObservHandler(entries, observer));
        this.resizeObserver.observe(document.body);

        this.$btn.addEventListener('click', () => {
            me.$btn.classList.add('d-none');
            me.$video.play();
        });

        this.$video.addEventListener('ended', () => {
            me.$btn.classList.remove('d-none');
        });

        this.$video.addEventListener('click', () => {
            me.$btn.classList.remove('d-none');
            me.$video.pause();
        });
    }

    /**
     * Handler for HTML ResizeObserve API use resize-observer-polyfill' for IE.
     *
     * @param entries
     */
    resizeObservHandler(entries) {
        const me = this;

        // eslint-disable-next-line no-restricted-syntax
        for (const entry of entries) {
            if (entry.contentRect.width > 768) {
                me.setVideoSource('Desktop');
            } else {
                me.setVideoSource('Mobile');
            }
        }
    }

    /**
     *  Change video and poster source files.
     *
     * @param newState
     */
    setVideoSource(newState) {
        const me = this;

        if (me.current !== newState) {
            me.current = newState;

            me.$video.pause();
            me.$source.setAttribute('src', me.options[`video${newState}`]);
            me.$video.poster = me.options[`poster${newState}`];
            me.$video.load();
        }
    }
}
