/**
 * Header.class
 *
 * Handling of page header.
 *
 * (c) notwenidges übel UG, Dimitri Preiß (preiss@notwenidges-uebel.de)
 */

export default class Contact {
    config = {
        formSelector: '#contactForm',
        successSelector: '#contactSuccess',
        errorSelector: '#contactError',
        submitSelector: '#contactSubmit',
        validationSelector: '#contactValidation',
        requiredFields: [
            'name',
            'family',
            'message',
            'address',
            'zip',
            'city',
        ],
        url: '/assets/components/theme/connector.php',
    };

    constructor(elem, config) {
        this.config = {...this.config, ...config};
        this.$el = elem;

        this.init();
    }

    /**
     * Set dom references and register events.
     */
    init() {
        this.$form = this.$el.querySelector(this.config.formSelector);
        this.$success = this.$el.querySelector(this.config.successSelector);
        this.$error = this.$el.querySelector(this.config.errorSelector);
        this.$submit = this.$el.querySelector(this.config.submitSelector);
        this.$validation = this.$el.querySelector(this.config.validationSelector);

        this.registerEvent();
    }

    registerEvent() {
        const me = this;

        this.$form.querySelectorAll('[type="text"][required], [type="email"][required], textarea').forEach((elem) => {
            elem.addEventListener('keyup', (event) => {
                if (event.target.value !== '') {
                    event.target.classList.add('success');
                    event.target.classList.remove('error');
                } else {
                    event.target.classList.remove('success');
                    event.target.classList.add('error');
                }

                me.validateForm();
            });
        });

        this.$form.querySelectorAll('[type="checkbox"][required]').forEach((elem) => {
            elem.addEventListener('change', () => {
                me.validateForm();
            });
        });

        this.$submit.addEventListener('click', () => {
            if (me.validateForm()) {
                me.sendForm();
            } else {
                this.showValidation();
            }
        });
    }

    validateForm() {
        this.fromData = {};
        this.isValid = true;

        // eslint-disable-next-line no-restricted-syntax
        for (const entry of new FormData(this.$form)) {
            if (this.config.requiredFields.includes(entry[0])) {
                if (entry[1] === '') {
                    this.isValid = false;
                }
            }
        }

        if (!this.$form.querySelector('[type="checkbox"]').checked) {
            this.isValid = false;
        }

        this.$submit.disabled = !this.isValid;

        return this.isValid;
    }

    showSuccess() {
        this.$success.classList.remove('d-none');

        this.$form.classList.add('d-none');
        this.$error.classList.add('d-none');
        this.$validation.classList.add('d-none');
    }

    showError() {
        this.$error.classList.remove('d-none');

        this.$form.classList.add('d-none');
        this.$success.classList.add('d-none');
        this.$validation.classList.add('d-none');
    }

    showValidation() {
        this.$validation.classList.remove('d-none');
        this.$form.classList.remove('d-none');

        this.$success.classList.add('d-none');
        this.$error.classList.add('d-none');
    }

    sendForm() {
        const me = this;

        const data = new FormData(me.$form);
        const object = {};
        data.forEach((value, key) => {
            object[key] = value;
        });

        const params = new URLSearchParams({
            action: 'themeajax',
            run: 'sendcontact',
            context: 'web',
            ...object,
        }).toString();

        // eslint-disable-next-line no-undef
        const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');

        xhr.open('POST', this.config.url);
        xhr.onreadystatechange = () => {
            if (xhr.readyState > 3 && xhr.status === 200) {
                me.success(JSON.parse(xhr.responseText));
            }
        };
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send(params);
        return xhr;
    }

    success(r) {
        if (r.results.error === false && r.results.valid === true && r.results.status === true) {
            this.showSuccess();
        } else if (r.results.status === false && r.results.valid === false) {
            this.showValidation();
        } else {
            this.showError();
        }
    }
}
