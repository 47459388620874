/**
 * Header.class
 *
 * Handling of page header.
 *
 * (c) notwenidges übel UG, Dimitri Preiß (preiss@notwenidges-uebel.de)
 */

export default class Header {
    config = {};

    oldY = 0;

    constructor(elem, config) {
        this.config = config;
        this.oldY = 0;
        this.elem = elem;

        this.init();
    }

    /**
     * Set dom references and register events.
     */
    init() {
        this.navToggle = document.getElementById(this.config.navToggler);
        this.mainnav = document.getElementById(this.config.mainnav);
        this.body = document.querySelector('body');

        this.registerEvent();
    }

    /**
     * Register events.
     */
    registerEvent() {
        const me = this;

        // burger toggle button
        this.navToggle.addEventListener('click', (event) => {
            me.onNavToggleHandler(event);
        }, false);

        // window scroll event
        window.addEventListener('scroll', me.onScrollhandler.bind(this));
    }

    /**
     * Handling header class toggling based on scroll position.
     */
    onScrollhandler() {
        const me = this;

        const newY = window.scrollY;

        if (newY === 0) {
            me.elem.classList.remove('scroll-in');
            me.elem.classList.remove('prepare-scroll-in');
            me.elem.classList.remove('enable-transition');
        }

        if (newY > 200 && newY < 500) {
            me.elem.classList.add('prepare-scroll-in');
        }

        if (newY < me.oldY && newY > 500) {
            me.elem.classList.add('enable-transition');
            me.elem.classList.add('scroll-in');

            setTimeout(() => {
                if (typeof logo !== 'undefined') {
                    me.config.logo.play();
                }
            }, 6000);
        }

        if (newY > me.oldY && newY > 500) {
            me.elem.classList.add('enable-transition');
            me.elem.classList.remove('scroll-in');
        }

        me.oldY = newY;
    }

    /**
     * Handle click on burger icon to toggle main nav.
     *
     * @param event
     */
    onNavToggleHandler(event) {
        const me = this;

        event.preventDefault();
        me.mainnav.classList.toggle('is-active');
        me.navToggle.classList.toggle('active');
        me.body.classList.toggle('menu-is-active');
    }
}
