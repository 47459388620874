/**
 * BubbleStack.class
 *
 * A stack of circle buttons they crossfade in loop. Click on deck will expand the stack.
 *
 * (c) notwendiges übel UG, Dimitri Preiß (preiss@notwenidges-uebel.de)
 */
export default class BubbleStack {
    options = {
        bubble: '.bubble',
        expand: 'expand',
        active: 'active',
        speed: 2500,
        delay: 2000,
    };

    constructor(elem, config) {
        this.$el = elem;
        this.options = {...this.options, ...config, ...elem.dataset};

        this.init();
    }

    /**
     * Init logic and build dom.
     */
    init() {
        this.currentIndex = 0;
        this.nextIndex = 1;
        this.bubbles = this.$el.querySelectorAll(this.options.bubble);

        // set first element active
        this.bubbles[0].classList.add(this.options.active);

        if (this.options.expand === 'expand') {
            this.registerEvent();
        }

        // start interval if not entered
        if (!this.$el.classList.contains(this.options.expand)) this.startCrossFade();

        this.$el.BubbleStack = this;
    }

    /**
     * Register related events.
     */
    registerEvent() {
        const me = this;

        me.$el.addEventListener('mouseenter', () => {
            me.$el.classList.add(me.options.expand);
            me.stopCrossFade();
            clearTimeout(me.close);
        });

        me.$el.addEventListener('mouseleave', () => {
            me.close = setTimeout(() => {
                me.$el.classList.remove(me.options.expand);
                me.startCrossFade();
            }, me.options.delay);
        });
    }

    /**
     * Start crossfade intervall.
     */
    startCrossFade() {
        const me = this;

        me.fade = setInterval(() => {
            me.bubbles[me.currentIndex].classList.remove(me.options.active);
            me.bubbles[me.nextIndex].classList.add(me.options.active);

            me.currentIndex = me.nextIndex;

            if (me.nextIndex + 1 >= me.bubbles.length - 1) {
                me.nextIndex = 0;
            } else {
                me.nextIndex += 1;
            }
        }, me.options.speed);
    }

    /**
     * Stop crossfade inervall.
     */
    stopCrossFade() {
        clearTimeout(this.fade);
    }
}
