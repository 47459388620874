export default class NoneResponsiveVideo {
    constructor(elem) {
        this.elem = elem;
        this.video = this.elem.querySelector('video') || false;
        this.btn = this.elem.querySelector('.btn') || false;

        if (this.btn && this.video) {
            this.elem.addEventListener('click', () => {
                if (this.video.paused) {
                    this.video.play();
                    this.elem.classList.add('played');
                } else {
                    this.video.pause();
                    this.elem.classList.remove('played');
                }
            });
        }
    }
}
