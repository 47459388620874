/**
 * Filter.class
 *
 * Handle video implementation on detail pages.
 *
 * (c) notwenidges übel UG, Dimitri Preiß (preiss@notwenidges-uebel.de)
 */

import queryString from 'query-string';

export default class Filter {
    options = {
        itemSelector: 'li',
        chipSelector: '#selected-filter',
        productsSelector: '#products > div',
        dataName: 'data-name',
        activeClass: 'active',
    };

    constructor(elem, config) {
        this.options = {...this.options, ...config, ...elem.dataset};
        this.$el = elem;
        this.$products = document.querySelectorAll(this.options.productsSelector);
        this.$dropdowns = Array.from(document.querySelectorAll('.dropdown-btn'));

        this.init();
    }

    init() {
        this.$items = this.$el.querySelectorAll(this.options.itemSelector);
        this.$select = this.$el.querySelectorAll('select');
        this.$chipWrapper = document.querySelector(this.options.chipSelector);

        this.key = {};
        this.$items.forEach((e) => {
            this.key = {...this.key, [e.dataset.name]: e.textContent};
        });

        this.active = [];
        const hash = queryString.parse(window.location.hash, {arrayFormat: 'comma'});
        if (typeof hash.filter !== 'undefined') {
            if (typeof hash.filter === 'string') {
                this.active = [hash.filter];
            } else {
                this.active = [...hash.filter];
            }
        }

        this.$all = document.createElement('li');
        this.$all.classList.add('badge', 'badge-pill', 'badge-primary');
        this.$all.innerText = 'Filter löschen';

        this.registerEvent();
        this.buildFilter();
    }

    registerEvent() {
        const me = this;

        me.$items.forEach((item) => {
            item.addEventListener('click', (e) => {
                me.chipsClickHandler(e);
            });
        });

        me.$select.forEach((item) => {
            item.addEventListener('change', (e) => {
                // me.chipsChangeHandler(e);;
                me.chipsChangeHandler(e);
                // eslint-disable-next-line no-param-reassign
                item.value = item[0].innerText;
            });
        });

        me.$all.addEventListener('click', () => {
            me.allClickHandler();
        });

        this.$dropdowns.forEach((elem) => {
            elem.addEventListener('click', () => {
                elem.parentNode.classList.toggle('show');
            });
        });

        // Close the dropdown menu if the user clicks outside of it
        window.onclick = (event) => {
            if (!event.target.closest('.dropdown')) {
                this.$dropdowns.forEach((elem) => {
                    elem.parentNode.classList.remove('show');
                });
            }
        };
    }

    /**
     * Handle chips click event.
     *
     * @param e
     */
    chipsClickHandler(e) {
        const me = this;

        if (me.active.includes(e.target.dataset.name)) {
            me.active = me.active.filter((i) => i !== e.target.dataset.name);
            me.$el.querySelectorAll(`[data-name='${e.target.dataset.name}']`).forEach((item) => {
                item.classList.remove(me.options.activeClass);
            });
        } else {
            me.active = [...me.active, e.target.dataset.name];
        }

        // e.target.classList.toggle(me.options.activeClass);
        me.buildFilter();

        e.target.closest('.show').classList.remove('show');
    }

    chipsChangeHandler(e) {
        const me = this;

        if (me.active.includes(e.target.value)) {
            me.active = me.active.filter((i) => i !== e.target.value);
        } else {
            me.active = [...me.active, e.target.value];
        }
        me.buildFilter();
    }

    /**
     * Handle delete all chip click event.
     */
    allClickHandler() {
        const me = this;

        me.active = [];
        me.setHash();
        me.$items.forEach((item) => {
            item.classList.remove(me.options.activeClass);
        });
        me.buildFilter();
    }

    /**
     * Build filter chips list.
     */
    buildFilter() {
        const me = this;

        // rest filter
        this.$chipWrapper.innerHTML = '';
        me.setHash();

        if (this.active.length > 0) {
            this.active.forEach((text) => {
                me.$el.querySelectorAll(`[data-name='${text}']`).forEach((item) => {
                    item.classList.add(me.options.activeClass);
                });
                me.buildItem(text);
            });
            this.$chipWrapper.appendChild(this.$all);
        }

        this.buildListing();
    }

    buildListing() {
        this.$products.forEach((elem) => {
            if (this.active.length <= 0) {
                elem.classList.remove('d-none');
            } else {
                const intersection = this.active.filter((x) => elem.classList.contains(x));
                if (intersection.length > 0) {
                    elem.classList.remove('d-none');
                } else {
                    elem.classList.add('d-none');
                }
            }
        });
    }

    /**
     * Build and append chip-item.
     * @param text
     */
    buildItem(text) {
        const me = this;

        const item = document.createElement('li');
        item.classList.add('badge');
        item.innerText = me.getNameByKey(text) || '';
        item.setAttribute('data-name', text);

        item.addEventListener('click', (e) => {
            me.chipsClickHandler(e);
        });

        me.$chipWrapper.appendChild(item);
    }

    /**
     * This method build query string and append as hash to the current uri, to prevent reloading. To use history API
     * is an alternative but put a lot of changes to history. These can be iritate the user if he use the
     * browsers go back function.
     */
    setHash() {
        window.location.hash = queryString.stringify({filter: this.active}, {arrayFormat: 'comma'});
    }

    /**
     * Get name of chips value.
     *
     * @param name
     * @returns {*}
     */
    getNameByKey(name) {
        const k = Object.keys(this.key)
            .filter((key) => key === name);
        if (k.length > 0) {
            return this.key[k[0]];
        }

        return '';
    }
}
