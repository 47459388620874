/**
 * Modal.class
 *
 * Simple modal window.
 *
 * (c) notwenidges übel UG, Dimitri Preiß (preiss@notwenidges-uebel.de)
 */

export default class Modal {
    options = {
        close: '.modal-close',
        overlay: '.modal-overlay',
        visibleClass: 'show',
        bodyActiveClass: 'modal-is-active',
    };

    /**
     * Simple modal window.
     *
     * @param elem
     * @param config
     */
    constructor(elem, config) {
        this.options = {...this.options, ...config, ...elem.dataset};
        this.$el = elem;

        this.init();
    }

    /**
     * Set dom references and register events.
     */
    init() {
        this.id = this.$el.getAttribute('id');
        this.iFrame = this.$el.querySelector('iframe');
        this.body = document.querySelector('body');
        this.registerEvent();

        this.$el.Modal = this;
    }

    /**
     * Register events.
     */
    registerEvent() {
        const me = this;

        this.$el.addEventListener('click', () => {
            me.close();
        });

        this.$el.querySelectorAll(this.options.close).forEach((elem) => {
            elem.addEventListener('click', () => {
                me.close();
            }, false);
        });

        document.querySelectorAll(`[href$="#${this.id}"]`).forEach((elem) => {
            elem.addEventListener('click', (event) => {
                event.preventDefault();
                me.open();
            }, false);
        });

        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                me.close();
            }
        });
    }

    /**
     * Open
     */
    open() {
        this.$el.classList.add(this.options.visibleClass);
        this.body.classList.add(this.options.bodyActiveClass);
    }

    /**
     * Close modal window and stop iframe videos.
     */
    close() {
        // stop youtube videos
        if (this.iFrame) {
            const {src} = this.iFrame;
            this.iFrame.src = src;
        }

        this.$el.classList.remove(this.options.visibleClass);
        this.body.classList.remove(this.options.bodyActiveClass);
    }
}
