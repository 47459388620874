import Swiper, {
    Navigation, Pagination, Scrollbar, Autoplay,
} from 'swiper';
import lottie from 'lottie-web';
import Parallax from 'parallax-js';
import Rellax from 'rellax';
import AOS from 'aos';
import BubbleStack from './module/bubblestack';
import Header from './module/header';
import Modal from './module/modal';
import Video from './module/video';
import NoneResponsiveVideo from './module/none-responsive-video';
import Filter from './module/filter';
import Contact from './module/contact';
import Quiz from './module/quiz';
import LqipLazyLoader from './module/lqip';
import Collapse from '../../node_modules/bootstrap.native/dist/components/collapse-native.esm';
import Dropdown from '../../node_modules/bootstrap.native/dist/components/dropdown-native.esm';
import Tab from '../../node_modules/bootstrap.native/dist/components/tab-native.esm';
import animationData from './module/Ahoj_V013.json';
import Userlike from './module/userlike';

AOS.init();

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay]);

const animateCSS = (element, animation, prefix = 'animate__') => {
    return new Promise((resolve) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);

        if (node) {
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            // eslint-disable-next-line no-inner-declarations
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                // node.classList.add('animate__animated_end');
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, {once: true});
        }
    });
};

window.addEventListener('load', () => {
    /**
     * Logo
     */
    const logo = lottie.loadAnimation({
        container: document.getElementById('signet'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
    });

    logo.addEventListener('complete', () => {
        logo.goToAndStop(0);
    });

    document.getElementById('signet').addEventListener('mouseover', () => {
        logo.play();
    });

    /** Single Video */
    document.querySelectorAll('[data-single-video]').forEach((elem) => {
        // eslint-disable-next-line
        new NoneResponsiveVideo(elem);
    });

    /** Quiz */
    document.querySelectorAll('[data-quiz]').forEach((elem) => {
        // eslint-disable-next-line
        new Quiz(elem, {});
    });

    /** BubbleStack */
    document.querySelectorAll('#bubbleStack').forEach((elem) => {
        // eslint-disable-next-line
        new BubbleStack(elem, {});
    });

    /** Collapse */
    document.querySelectorAll('[data-toggle="collapse"]').forEach((elem) => {
        // eslint-disable-next-line no-param-reassign
        elem.collapse = new Collapse(elem);

        elem.addEventListener('show.bs.collapse', () => {
            const node = document.querySelector('[data-bs-target="#ingredients"]') || false;
            window.lastNodePos = window.scrollY || 0;

            if (node) {
                window.scrollTo({
                    top: node.getBoundingClientRect().top + window.scrollY - 40,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }, false);

        elem.addEventListener('hide.bs.collapse', () => {
            window.scrollTo({
                top: window.lastNodePos,
                left: 0,
                behavior: 'smooth',
            });
        }, false);
    });

    document.querySelectorAll('[data-single-toggle]').forEach((elem) => {
        const targetEl = document.querySelector(elem.dataset.target) || false;

        console.log(elem, elem.dataset.target, targetEl);

        if (targetEl) {
            elem.addEventListener('click', () => {
                targetEl.classList.toggle('show');
            });
        }
    });

    /** Tabs */
    document.querySelectorAll('[data-tabs]').forEach(() => {
        // eslint-disable-next-line no-param-reassign
        const myTabsCollection = document.querySelectorAll('.nav-tabs a') || [];

        Array.from(myTabsCollection).forEach((tab) => new Tab(tab));
    });

    /**

     // first, we reference the .nav component that holds all tabs
     var myTabs = document.getElementById('myTabs');

     // let's give the initialization a JavaScript reference for the "target" option
     var myTabsCollection = myTabs.getElementsByTagName('A');

     // initialize the component for all items in the collection
     Array.from(myTabsCollection).forEach(
     tab => new BSN.Tab( tab )
     );

     */

    /** Dropdown */
    document.querySelectorAll('[data-toggle="dropdown"]').forEach((elem) => {
        // eslint-disable-next-line no-param-reassign
        elem.Collapse = new Dropdown(elem);
    });

    /** Filter */
    document.querySelectorAll('[data-filter]').forEach((elem) => {
        // eslint-disable-next-line no-param-reassign
        elem.Filter = new Filter(elem);
    });

    /** Video */
    document.querySelectorAll('[data-video]').forEach((elem) => {
        // eslint-disable-next-line no-param-reassign
        elem.Video = new Video(elem, {});
    });

    /** Contact */
    document.querySelectorAll('[data-contact]').forEach((elem) => {
        // eslint-disable-next-line no-param-reassign
        elem.Video = new Contact(elem, {});
    });

    /** Header */
    // eslint-disable-next-line
    new Header(document.getElementById('header'), {
        header: 'header',
        footer: 'footer',
        mainnav: 'mainnav',
        mobilenav: 'mobile-icon-nav',
        navToggler: 'hamburger-icon',
        logo,
    });

    /** Loq quality imge placeholder */
    document.querySelectorAll('[data-lqip]').forEach((elem) => {
        // eslint-disable-next-line
        new LqipLazyLoader(elem, []);
    });

    /** Modal */
    document.querySelectorAll('.modal').forEach((elem) => {
        // eslint-disable-next-line
        new Modal(elem, []);
    });

    /** Gallery */
    document.querySelectorAll('.swiper-gallery').forEach((elem) => {
        const swiperElem = elem.querySelector('.swiper-container');
        const id = elem.getAttribute('id');

        const options = {
            loop: true,
            allowClick: true,
            observer: true,
            observeParents: true,
            grabCursor: true,
            pagination: {
                clickable: true,
                el: document.getElementById(`${id}-pagination`),
                type: 'bullets',
            },
            navigation: {
                nextEl: document.getElementById(`${id}-next`),
                prevEl: document.getElementById(`${id}-prev`),
            },
            autoplay: {
                enabled: false,
                duration: elem.dataset.duration,
            },

            on: {
                init(a) {
                    a.$el[0].querySelectorAll('.lqip-lazy-loader').forEach((e) => {
                        // eslint-disable-next-line
                        new LqipLazyLoader(e, {
                            useIntersection: false,
                        });
                    });
                },
                slideNextTransitionEnd() {
                    // eslint-disable-next-line no-console
                    animateCSS(`#${id} .swiper-slide-active .display-1`, 'fadeInTopLeft');
                    animateCSS(`#${id} .swiper-slide-active p`, 'faxx^^^deInTopLeft');

                    // display-1
                },
                slidePrevTransitionEnd() {
                    // eslint-disable-next-line no-console
                    animateCSS(`#${id} .swiper-slide-active .display-1`, 'fadeInTopLeft');

                    // display-1
                },
            },
        };

        // eslint-disable-next-line
        const sw = new Swiper(swiperElem, options);
    });

    /** Gallery-Slider */
    window.addEventListener('resize', () => {
        document.querySelectorAll('.swiper-gallery-slider').forEach((elem) => {
            const swiperElem = elem.querySelector('.swiper-container');

            if (typeof swiperElem.Swiper === 'undefined') {
                const id = elem.getAttribute('id');

                const options = {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    observer: true,
                    observeParents: true,
                    grabCursor: true,
                    updateOnImagesReady: true,
                    breakpoints: {
                        // when window width is >= 320px
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                        },
                        // when window width is >= 480px
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 0,
                        },
                    },
                    navigation: {
                        nextEl: document.getElementById(`${id}-next`),
                        prevEl: document.getElementById(`${id}-prev`),
                    },
                    scrollbar: {
                        el: document.getElementById(`${id}-scrollbar`),
                        draggable: true,
                    },
                    pagination: {
                        clickable: true,
                        el: document.getElementById(`${id}-pagination`),
                        type: 'bullets',
                    },
                    autoplay: {
                        enabled: false,
                        duration: elem.dataset.duration,
                    },
                    on: {
                        init(a) {
                            a.$el[0].querySelectorAll('.lqip-lazy-loader').forEach((e) => {
                                // eslint-disable-next-line
                                new LqipLazyLoader(e, {
                                    useIntersection: false,
                                });
                            });
                        },
                    },
                };

                swiperElem.Swiper = new Swiper(swiperElem, options);
            }
        });
    });

    const resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);

    document.body.classList.add('loaded');

    // animate da geht was
    animateCSS('.da-geht-was-header', 'bounceInDown');

    /*
    // Hero Gallery
    document.querySelectorAll('.swiperHero').forEach((elem) =>  {
        const swiperElem = elem.querySelector('.swiper-container');

        const options = {
            loop: true,
            initialSlide: Math.floor(Math.random() * elem.querySelectorAll('.swiper-slide').length),
            autoplay: {
                enabled: true,
                duration: 2000
            },
            speed: 1000,
            effect: 'fade',
            on: {
                slideChange(a) {
                    var a = elem.querySelectorAll('.lqip-lazy-loader')[a.activeIndex];
                    if (!a.LqipLazyLoader) {
                        new LqipLazyLoader(a, []);
                    }
                }
            },
        };

        const sw = new Swiper(swiperElem, options);

        const galleryCallback = (entries, observer) => {
            entries.forEach((entry) => {
                console.log(entry);
                if (entry.isIntersecting) {
                    sw.autoplay.start();
                    console.log('in');
                } else {
                    sw.autoplay.stop();
                }
            });
        };

        const observer = new IntersectionObserver(galleryCallback, {
            threshold: 0.2
        });

        observer.observe(elem);
    }); */
});

if (window.innerWidth > 500 && document.querySelectorAll('.no-touch-device .parallax').length > 0) {
    document.querySelectorAll('.no-touch-device .parallax').forEach((elem) => {
        // eslint-disable-next-line no-param-reassign
        elem.ParallaX = new Parallax(elem);
    });
}

// eslint-disable-next-line
new Rellax('.parallax-js');

window.addEventListener('load', () => {
    const userlikeBtn = document.getElementById('userlikeBtn') || false;

    if (userlikeBtn) {
        // eslint-disable-next-line no-new
        new Userlike(userlikeBtn);
    }
});

document.addEventListener('click', () => {
    document.querySelectorAll('[data-toggle="dropdown"]').forEach((elem) => {
        if (elem.Collapse && elem.closest('.dropdown').classList.contains('show')) {
            // elem.Collapse.hide();
        }
    });
});
